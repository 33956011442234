
import React from "react";
import { useState } from "react";
import ReactMarkdown from 'react-markdown';

// Components
import useFetch from "../useFetch";

const Projects = () => {

    // const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/landing-page?[projects][populate]=*`)
    const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/projects?populate=*`)

    let projects = [];
    let pagination = 0;

    const reveal = () => {
        var reveals = document.querySelectorAll(".reveal")
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;
            if (elementTop < windowHeight - elementVisible) {
              reveals[i].classList.add("active");
            } else {
              reveals[i].classList.remove("active");
            }
          }
    }

    window.addEventListener("scroll", reveal);
    reveal()

    if (data) {

        projects = data.data
        pagination = projects.length
        console.log(`projects`, data)

        return (
            <div className="projects-overflow">
            { projects.map((project) => 
                <div className="projects-wrapper" style={{backgroundImage: `linear-gradient(0deg, ${project.attributes.Bottom_Gradient_Hexcode} 0%, ${project.attributes.Top_Gradient_Hexcode} 100%)`}}>                
                    <div className="project-card-container">
                    <ReactMarkdown className="alt-heading1">{project.attributes.Project_Title}</ReactMarkdown>
                                    <ReactMarkdown className="alt-heading2">{project.attributes.Project_Title}</ReactMarkdown>
                        <div className="project-card">
                            <div className="project-section project-information">
                                <div className="project-title-card">
                                    <ReactMarkdown>{project.attributes.Project_Title}</ReactMarkdown>
                                    <h4>{project.attributes.Location}</h4>
                                    <h4>{project.attributes.Year}</h4>
                                </div>
                                <div className="collaborators-container">
                                    <h3>Collaborators</h3>
                                    {Object.entries(project.attributes.Collaborators).map((key) => 
                                        <div className="collaborator-wrapper">
                                            <p className="credit">{key[0]} </p>
                                            <p className="collaborator">{key[1]}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="project-section-large ">
                                <div className="gallery">
                                    <img src={project.attributes.Gallery.data[0].attributes.url}/>
                                </div>
                                <div className="project-description">
                                    <ReactMarkdown>{project.attributes.Project_Description}</ReactMarkdown>
                                </div>
                            </div>
                            
                        </div>
                
                    </div>
                </div>
            )}
            <div>
                { }
            </div>
            </div>
        )
    }
    
}

export default Projects;