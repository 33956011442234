import React from "react";
import { useState } from "react";
import ReactMarkdown from 'react-markdown';

// Components
import useFetch from "../useFetch";

const Bios = () => {

    const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/landing-page?populate[Bio_Background_Img][populate]=*`)

    let bio = null;

    // const reveal = () => {
    //     var reveals = document.querySelectorAll(".reveal")
    //     for (var i = 0; i < reveals.length; i++) {
    //         var windowHeight = window.innerHeight;
    //         var elementTop = reveals[i].getBoundingClientRect().top;
    //         var elementVisible = 150;
    //         if (elementTop < windowHeight - elementVisible) {
    //           reveals[i].classList.add("active");
    //         } else {
    //           reveals[i].classList.remove("active");
    //         }
    //       }
    // }

    // window.addEventListener("scroll", reveal);
    // reveal()

    if (data) {

        bio = data.data.attributes
        
        return (
            <div className="bios-wrapper" style={{backgroundImage: `url(${bio.Bio_Background_Img.data.attributes.url})`}}>                
                <div className="bios">
                    <h2 className="reveal">Chris and Ama Law</h2>
                    <ReactMarkdown className="reveal">{bio.Biography}</ReactMarkdown>
                </div>
                <div className="cv-wrapper">
                    <h3><a href={`${bio.Chris_CV}`}>Chris Law CV</a> | <a href={`${bio.Ama_CV}`}>Ama Law CV</a></h3>
                    
                </div>
            </div>
        )
    }
    
}

export default Bios;