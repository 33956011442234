import React from "react";
import { useState } from "react";
import logo from '../CharmaProjectLogo.svg';

// Components
import useFetch from "../useFetch";

const Navigation = () => {

    const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/landing-page?populate=*`)

    let navBar = null;

    if (data) {

        navBar = data.data.attributes.Navigation[0]
        
        return (
            <div className="nav-wrapper">                
                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 237.14 204.18">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g>
                        <path class="cls-1" d="M104.74,164.82c-4.82-4.85-9.3-9.64-14.11-14.07-2.36-2.17-2.93-4.28-2.49-7.36,1.4-9.67.87-19.42.66-29.14-.02-.89-.05-1.69-1.3-1.62-13.17.75-26.52-.21-39.36,3.7-11.22,3.41-19.86,9.93-24.07,21.31-5.51,14.91,1.71,32.41,16.29,39.72,6.02,3.02,12.29,4.53,19.03,3.29,1.54-.28,2.64.14,3.75,1.24,4.78,4.74,9.59,9.45,14.54,14.02,1.55,1.43.93,1.76-.48,2.45-19.52,9.58-37.88,7.04-55.08-5.56-12.55-9.2-20.07-21.55-21.76-37.16-2.03-18.86,4.09-34.69,18.2-47.34,9.55-8.57,21.18-12.44,33.63-14.41,11.05-1.75,22.18-1.95,33.32-1.99,1.95,0,2.49-.48,2.47-2.47-.11-10.89-.62-21.79.81-32.64,1.89-14.35,5.99-27.91,16.21-38.64,12.82-13.47,28.38-21.2,47.23-17.02,22.95,5.09,38.51,19.04,44.02,42.47,5.41,22.99-5.66,46.96-26.2,58.65-9.55,5.43-19.91,7.72-30.62,8.94-9.02,1.02-18.09,1.22-27.15,1.13-2.17-.02-2.93.56-2.84,2.81.44,10.56.42,21.12-.75,31.64-.67,6.02-1.79,11.95-3.96,18.04ZM108.73,81.04c0,2.99.05,5.98-.02,8.97-.04,1.44.47,1.83,1.9,1.73,2.73-.2,5.48-.17,8.22-.27,9.87-.35,19.76-.46,29.39-3.13,11.33-3.14,20.21-9.3,24.73-20.51,5.69-14.1.58-30.19-12.15-38.63-10.04-6.66-20.63-8.18-31.55-2.39-8.91,4.72-14.2,12.55-17.31,21.94-3.48,10.51-3.5,21.4-3.23,32.3Z"/>
                        <path class="cls-1" d="M164.86,174.35c0,2.83,0,5.65,0,8.48,0,1.26-.21,2.42-1.81,2.41-1.55,0-1.86-1.06-1.85-2.38.02-3.49.02-6.98,0-10.47,0-.54.25-1.27-.48-1.5-.55-.17-.85.41-1.18.76-1.02,1.09-2.12,2.12-3.03,3.3-1.31,1.7-2.48,1.96-3.9.12-.91-1.18-2.01-2.21-3.04-3.3-.38-.4-.69-1.12-1.35-.9-.74.24-.37,1-.37,1.52-.03,3.41-.03,6.82-.01,10.22,0,1.36-.17,2.61-1.9,2.59-1.58-.02-1.82-1.24-1.83-2.46-.02-5.65-.01-11.3.02-16.96,0-.99.04-2.08,1.24-2.48,1.24-.41,1.98.32,2.73,1.21,1.56,1.83,3.31,3.51,4.8,5.39,1.15,1.44,1.96,1.3,3.08,0,1.68-1.95,3.52-3.76,5.26-5.66.67-.73,1.39-1.22,2.4-.85,1.03.38,1.23,1.26,1.23,2.23,0,2.91,0,5.82,0,8.73h.01Z"/>
                        <path class="cls-1" d="M161.88,132.53c-.06,6.47-4.58,10.9-11.06,10.84-6.6-.06-11.08-4.62-11-11.21.08-6.36,4.75-10.95,11.11-10.91,6.5.04,11.02,4.69,10.95,11.28ZM143.66,132.33c-.01,4.36,2.74,7.21,7.06,7.29,4.38.08,7.41-2.85,7.46-7.21.05-4.27-2.84-7.2-7.15-7.26-4.55-.06-7.36,2.68-7.37,7.19Z"/>
                        <path class="cls-1" d="M96.43,174.43c0,2.25.1,4.5-.03,6.74-.09,1.56.86,4.08-1.92,3.98-2.65-.09-1.69-2.55-1.82-4.14-.1-1.24-.03-2.49,0-3.74.02-1.4-.47-2.17-2.05-2.1-1.99.09-3.99.06-5.99,0-1.48-.04-2.12.61-2.09,2.09.05,1.91,0,3.83,0,5.74,0,1.23-.38,2.17-1.79,2.18-1.37.01-1.9-.86-1.91-2.11,0-5.91-.01-11.81,0-17.72,0-1.21.45-2.19,1.83-2.19,1.52,0,1.84,1.01,1.9,2.35.23,5.79.26,5.8,6.15,5.77,4.41-.02,3.74.3,3.86-3.92.05-1.59-.73-4.07,1.95-4.03,2.65.04,1.8,2.5,1.87,4.09.1,2.33.03,4.66.03,6.99h.02Z"/>
                        <path class="cls-1" d="M125.2,174.12c0-2.83.02-5.65,0-8.48-.02-1.75.69-2.64,2.53-2.56,1.91.09,3.83-.04,5.73.16,2.66.28,4.81,2.16,5.3,4.59.53,2.66.29,5.26-2.21,6.89-1.44.94-1.42,1.72-.54,2.96.91,1.28,1.65,2.69,2.5,4.02.75,1.17,1.13,2.4-.3,3.22-1.44.82-2.29-.22-2.98-1.36-1.25-2.06-2.51-4.11-3.78-6.16-.4-.64-.85-1.33-1.75-1.09-.95.25-.84,1.1-.84,1.85,0,1.58-.02,3.16,0,4.74.02,1.22-.32,2.22-1.7,2.27-1.53.06-1.93-.98-1.93-2.32.01-2.91,0-5.82,0-8.73h-.01ZM128.87,169.68c-.44,2.14.6,2.8,2.6,2.72,2.53-.1,3.88-1.04,3.73-2.92-.19-2.36-1.92-2.49-3.78-2.59-2.1-.11-2.94.72-2.55,2.78Z"/>
                        <path class="cls-1" d="M120.81,132.32c0-2.82.04-5.64-.02-8.46-.04-1.76.69-2.66,2.47-2.65,1.74.02,3.49-.06,5.22.08,2.96.23,5.11,1.98,5.86,4.59.82,2.85.03,5.48-2.46,7.21-1.12.77-1.19,1.33-.5,2.36,1.01,1.52,1.91,3.11,2.88,4.65.71,1.14.81,2.26-.44,3.01-1.16.69-2.05.06-2.69-.97-1.31-2.11-2.62-4.23-3.94-6.34-.44-.7-.86-1.59-1.88-1.28-.97.31-.9,1.26-.9,2.1,0,1.58,0,3.15,0,4.73,0,1.13-.45,2-1.7,2-1.3,0-1.91-.75-1.9-2.08.03-2.99,0-5.97,0-8.96ZM127.07,125.1c-1.34-.13-2.81-.07-2.71,2.33.07,1.67-.07,3.15,2.33,3.14,2.72-.02,4.24-.86,4.16-2.76-.09-2.14-1.54-2.72-3.78-2.71Z"/>
                        <path class="cls-1" d="M194.9,132.23c.2-4.33,1.83-7.8,5.84-9.74,4.26-2.07,8.41-1.57,12.28,1.06,1.2.82,2.36,1.99,1.21,3.51-1.1,1.45-2.28.53-3.42-.32-3.25-2.43-7.99-2.07-10.44.7-2.21,2.51-2.16,7.51.11,9.93,2.61,2.79,7.05,3.03,10.41.48,1.16-.88,2.32-1.65,3.4-.14,1.06,1.47-.06,2.55-1.14,3.32-3.91,2.81-8.15,3.23-12.47,1.15-4.11-1.98-5.68-5.59-5.77-9.96Z"/>
                        <path class="cls-1" d="M120.2,183.23c-.08.89-.48,1.61-1.38,1.85-1.02.28-1.83-.14-2.26-1.08-.55-1.21-1.06-2.44-1.46-3.7-.41-1.31-1.18-1.93-2.56-1.79-.49.05-1.01.08-1.49,0-2.96-.52-4.76.59-5.3,3.62-.1.56-.35,1.11-.6,1.63-.53,1.1-1.36,1.73-2.61,1.21-1.28-.53-1.32-1.55-.88-2.7,1.5-3.87,3-7.74,4.5-11.61.75-1.94,1.48-3.88,2.26-5.8.4-.99,1.12-1.65,2.25-1.7,1.12-.05,1.93.47,2.34,1.5,2.36,6.02,4.71,12.05,7.06,18.08.06.15.09.32.14.49ZM110.74,170.25c-.68,1.48-2.23,2.75-1.34,4.38.22.41,2.5.4,2.73-.02.9-1.66-.54-2.94-1.39-4.36Z"/>
                        <path class="cls-1" d="M176.82,132.26c0-2.74.05-5.49-.02-8.23-.04-1.89.79-2.76,2.67-2.75,2.66.02,5.32,0,7.98,0,1.31,0,2.36.43,2.36,1.94,0,1.53-1.08,1.9-2.38,1.9-1.5,0-3,.1-4.49,0-1.82-.14-2.27.7-2.26,2.36.01,1.63.39,2.54,2.24,2.38,1.15-.1,2.33-.06,3.48.08.97.11,1.75.66,1.76,1.77.01,1.21-.75,1.84-1.86,1.93-1.24.1-2.49.09-3.74.05-1.93-.06-1.91,1.26-1.77,2.54.14,1.3-.84,3.27,1.66,3.4,1.74.09,3.49.02,5.24.06,1.21.02,2.19.47,2.21,1.83.02,1.36-.89,1.86-2.14,1.86-2.83.02-5.65.06-8.48.04-1.73,0-2.53-.87-2.47-2.67.08-2.82.02-5.65.02-8.48Z"/>
                        <path class="cls-1" d="M187.12,185.13c-.89.04-1.47-.25-1.84-.87-.25-.43-.4-.91-.59-1.37-1.9-4.66-2.73-5.09-7.81-4.37-.98.14-1.44.64-1.75,1.49-.45,1.25-.94,2.48-1.46,3.7-.48,1.14-1.33,1.77-2.57,1.29-1.27-.5-1.36-1.54-.92-2.69,1.49-3.87,2.99-7.73,4.49-11.59.72-1.85,1.42-3.71,2.15-5.56.46-1.15,1.18-2.01,2.56-2,1.27,0,1.95.81,2.37,1.88,2.26,5.79,4.51,11.58,6.74,17.38.56,1.46.03,2.41-1.37,2.71ZM179.66,169.77c-1.4,1.67-2.33,3.19-1.93,4.74.18.69,2.5.69,2.91.11,1.06-1.48-.64-2.77-.99-4.85Z"/>
                        <path class="cls-1" d="M226.39,133.81c0-2.07-.08-4.15.02-6.22.09-1.79-.47-2.68-2.39-2.46-.82.09-1.66.04-2.49,0-1.12-.07-1.94-.56-1.94-1.8,0-1.17.66-1.96,1.81-1.97,4.65-.05,9.29-.05,13.94.02,1.12.02,1.83.8,1.8,1.97-.03,1.22-.85,1.66-1.97,1.81-1.52.21-3.47-.76-4.48.59-1.04,1.39-.35,3.37-.39,5.09-.07,3.32-.01,6.64-.02,9.96,0,1.35-.27,2.66-1.92,2.62-1.55-.03-2-1.24-2-2.63,0-2.32,0-4.65,0-6.97h.02Z"/>
                        <path class="cls-1" d="M172.14,132.8c0,2.82.08,5.64-.02,8.45-.13,3.56-1.74,5.66-4.58,6.25-1.32.28-2.63.23-3.04-1.31-.38-1.43.62-2.14,1.9-2.37,1.74-.32,1.91-1.52,1.9-2.96-.03-5.39-.02-10.78-.02-16.16,0-1.48-.23-3.36,1.93-3.29,2.04.07,1.92,1.93,1.93,3.42.03,2.65,0,5.31,0,7.96Z"/>
                        <path class="cls-1" d="M111.68,191.42c2.32,0,4.64,0,6.96,0,1.54,0,2.64.65,2.65,2.29,0,1.54-1.14,2.2-2.49,2.22-4.81.07-9.61.09-14.42.01-1.44-.02-2.63-.75-2.5-2.46.11-1.54,1.18-2.07,2.6-2.07,2.4,0,4.81,0,7.21,0Z"/>
                        </g>
                    </g>
                </svg>
                <div className="button-wrapper">
                    <a href="mailto:Projectcharma@gmail.com"><h3>Contact</h3></a>
                    { navBar.Bttn_Toggle ? (
                       <a href={`${navBar.Button_Link}`}> 
                        <div className="bttn-large">
                                <h3>{navBar.Bttn_Text}</h3>
                            </div>
                        </a>
                    ) : ( null ) }
                </div>
            </div>
        )
    }
    
}

export default Navigation;