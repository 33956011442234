
import React from "react";
import { useState } from "react";
import ReactMarkdown from 'react-markdown';

// Components
import useFetch from "../useFetch";

const About = () => {

    const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/landing-page?populate=*`)

    let about = null;
    let logos = [];

    const reveal = () => {
        var reveals = document.querySelectorAll(".reveal")
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;
            if (elementTop < windowHeight - elementVisible) {
              reveals[i].classList.add("active");
            } else {
              reveals[i].classList.remove("active");
            }
          }
    }

    window.addEventListener("scroll", reveal);
    reveal()

    if (data) {

        about = data.data.attributes
        logos = about.Partners_Gallery.data

        return (
            <div className="about-wrapper">                
                <div className="about-card-container">
                    <div className="about-card">
                        <ReactMarkdown>{about.About_Who_Are_We}</ReactMarkdown>
                    </div>
                    <div className="about-card">
                        <ReactMarkdown>{about.What_We_Do}</ReactMarkdown>
                    </div>
                </div>
                <div className="logo-wrapper">
                    <h2>Our Partners</h2>
                    <div className="logo-container">
                        { logos.map((logo) => 
                            <div>
                                <img className="logo" src={`${logo.attributes.url}`} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
    
}

export default About;