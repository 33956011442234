import logo from './logo.svg';
import { useState } from "react";
import './App.css';
import useFetch from "./useFetch";

import Navigation from './partials/nav';
import Intro from './components/intro';
import Bios from './components/bios';
import About from './components/about';
import Projects from './components/projects';
import Contact from './components/contact';

import appHeight from "./appHeight";

function App() {

  const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/landing-page?populate=*`)

  if (data) {

    return (
      <div className="App">
        <Navigation />
        <Intro />
        <Bios /> 
        <About />
        <Projects />
      </div>
    );
  }
}

export default App;
