import React from "react";
import { useState } from "react";

// Components
import useFetch from "../useFetch";

const Intro = () => {

    const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/landing-page?populate[Landing_Image][populate]=*`)

    let intro = null;

    if (data) {

        intro = data.data.attributes
        
        return (
            <div className="intro-wrapper" style={{backgroundImage: `url(${intro.Landing_Image.data.attributes.url})`}}>                
                <div className="mission-statement">
                    <h1>{intro.Mission_Statement}</h1>
                    <h2>Pronounced “Karma” because it guides our lives.</h2>
                </div>
            </div>
        )
    }
    
}

export default Intro;